import React from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="footer padded-sec">
        <div className='abs-list ftrShape1'>
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12'>
              <Link to="/"><div className='ftr-logo'>

         <LazyLoad>       <img src="https://webapi.entab.info/api/image/SJSP/public/Images/ftr-logo.png" alt="St. Joseph's Convent School, Phagwara" /></LazyLoad>
                <div>
                  <p>St. Joseph's Convent School, Phagwara is an English Medium School founded under the aegis of the Diocese of Jalandhar</p>
                  <ul className='socialMedia'>
                    <li><Link to="https://www.facebook.com/StJosephsConventSchoolPhagwara/?hc_ref=ARRht-8mezJ2j4fBnJ0oS0q7rPfsEGmNvY4O1uR2Sm6AZr6oXJUn0Q13IQcttG9G8wM&fref=nf" target="_blank"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/facebook.png" alt="St. Joseph's Convent School, Phagwara" /></LazyLoad></Link></li>
                    <li><Link to="https://www.youtube.com/embed/pMf79DuDWBg?rel=0" target="_blank"><LazyLoad>
                      <img src="https://webapi.entab.info/api/image/SJSP/public/Images/youtube.png" alt="St. Joseph's Convent School, Phagwara" /></LazyLoad></Link></li>
                    <li><Link to="/" target="_blank"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/insta.png" alt="St. Joseph's Convent School, Phagwara" /></LazyLoad></Link></li>
                  </ul>
                </div>


              </div>
              </Link>
            </div>

            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12'>
              <div className='quick-link'>
                <h3>Important Links</h3>
                <ul>

                  <li><Link to="/Tc"><i class="bi bi-caret-right-fill"></i> TC Issued</Link></li>
                  <li><Link to="/News"><i class="bi bi-caret-right-fill"></i> Information Board</Link></li>
                  <li><Link to="/VideoGallery"><i class="bi bi-caret-right-fill"></i> Video Gallery</Link></li>
                  <li><Link to="/Gallery"><i class="bi bi-caret-right-fill"></i>  Photo Gallery</Link></li>
                  {/* <li><Link to="/"><i class="bi bi-caret-right-fill"></i> Career</Link></li> */}
                  <li><Link to="/ContactUs"><i class="bi bi-caret-right-fill"></i>  Contact Us </Link></li>
                </ul>
              </div>
            </div>

            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12 address'>
              <h3>Address</h3>
              <p><i class="bi bi-house-door-fill"></i> <span><Link to="https://maps.app.goo.gl/KPfrE5sseSrtmYwm7" target="_blank">St. Joseph’s Convent School, Phagwara, Punjab 144402</Link></span></p>
              <p><i class="bi bi-telephone-fill"></i> <span><a href="tel: 0657 - 2286677">0657 - 2286677</a></span></p>
              <p><i class="bi bi-envelope-fill"></i> <span> <a href="mailto: stjosephphg@gmail.com">stjosephphg@gmail.com</a></span></p>
            </div>
            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d109167.19125397995!2d75.67195809799384!3d31.235279027678143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x391af506c8dd606d%3A0x594a663bde8f609!2s6QP3%2B4PH%2C%20NH%2044%2C%20Phagwara%2C%20Punjab%20144402!3m2!1d31.235301099999997!2d75.7543645!5e0!3m2!1sen!2sin!4v1733379701387!5m2!1sen!2sin" width="100%" height="200" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>

        </div>
      </div>
      <div className='copyright'>
        ©️Copyright 2025 By St. Joseph’s School, Phagwara | Created By <Link to="https://entab.in">Entab Infotech : CampusCare</Link>
      </div>
    </>
  );
}

export default Footer;
