import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { postTransferCertificate } from '../Service/Api'; 
import Footer from '../Component/Footer';
import Header from '../Component/Header';

const Tc = () => {
  const navigate = useNavigate();
  const [admission, setAdmission] = useState("");
  const [name, setName] = useState("");
  const [admissionError, setAdmissionError] = useState("");
  const [nameError, setNameError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleAdmissionNoChange = (event) => {
    setAdmission(event.target.value);
    setAdmissionError(""); // Clear error on user input
  };

  const handleFullNameChange = (event) => {
    setName(event.target.value);
    setNameError(""); // Clear error on user input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Field-level validation
    let valid = true;
    
    // Validate Admission No
    if (!admission) {
      setAdmissionError("Please fill Admission Number");
      valid = false;
    }

    // Validate Student Full Name
    if (!name) {
      setNameError("Please fill Student Full Name");
      valid = false;
    }

    if (!valid) return;  // Stop form submission if validation fails
    
    try {
      const response = await postTransferCertificate(name, admission);
      if (response) {
        setSuccessMessage("Transfer Certificate is being processed. Your download will start shortly.");
        console.log(response.data);
        
        // Handle file download
        fetch(`https://webapi.entab.info/api/image/${response.data}`)
          .then(response => response.blob())  // Assuming the data is in a blob format; adjust if different
          .then(blob => {
            // Create a link element, use it to download the blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'tc.jpg';  // Set the file name and extension
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(() => alert('Failed to download data.'));  // Error handling
      }
    } catch (error) {
      console.error("Failed to post data", error);
      setAdmissionError("Failed to process your request. Please try again later.");
    }
  };

  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/"> Home</Link> </li>
          <li>Transfer Certificate</li>
        </ul>
      </div> 
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Transfer Certificate</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tcblock">
                <div className="tc">
                  <form onSubmit={handleSubmit}>
                    <div>
                      <div className="formfil">
                        <div className="form-group">
                          <label htmlFor="admission"><p>Admission No.</p></label>
                          <input 
                            type="text" 
                            className="form-control" 
                            value={admission} 
                            onChange={handleAdmissionNoChange} 
                          />
                          {admissionError && <div className="error-message">{admissionError}</div>}
                        </div>
                        <div className="form-group">
                          <label htmlFor="name"><p>Student Full Name</p></label>
                          <input 
                            type="text" 
                            className="form-control" 
                            value={name} 
                            onChange={handleFullNameChange} 
                          />
                          {nameError && <div className="error-message">{nameError}</div>}
                        </div>
                      </div>
                
                      {successMessage && <div className="success-message">{successMessage}</div>}
                
                      <div className="clr30"></div>
                      <input type="submit" className="viewdetailsbtn" value="Submit" />
                    </div>
                  </form>
                </div>
                <iframe 
                  src='https://webapi.entab.info/api/image/SJSP/public/pdf/TC-Sample.pdf#toolbar=0' 
                  width="100%" 
                  height="500">
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Tc;
